<template>
  <div>
    <Index />
  </div>
</template>

<script>
import Index from "../../components/TeamMembers/index";
export default {
  name: "TeamMembers",
  components: {
    Index,
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
