<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="pa-5 rounded-xl" elevation="0">
          <h2 class="text-left">Team Members</h2>
          <p class="text-left">
            <small>Add, remove and manage your team members</small>
          </p>
          <div class="mt-3 rounded-lg border pa-5">
            <v-row>
              <v-col cols="12" md="6">
                <h5 class="text-left">Members({{ members.length }})</h5>
                <div class="d-flex">
                  <div v-for="i in members.length" :key="i">
                    <v-img
                      class="mr-3"
                      :lazy-src="require('../../assets/avatar.png')"
                      :src="require('../../assets/avatar.png')"
                      style="height: 30px; width: 30px"
                    />
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <v-row justify-md="end" align="center" class="my-0 px-2">
                  <div class="mr-2 mb-2 mb-md-0">
                    <h5 class="">
                      {{ members.length }}/{{ user.accounts }} invited
                    </h5>
                    <v-progress-linear
                      color="success darken-2"
                      :value="(members.length / user.accounts) * 100"
                    ></v-progress-linear>
                  </div>
                  <v-btn
                    large
                    class="primary rounded-lg capitalize"
                    text
                    @click="addUser"
                  >
                    <v-icon class="mr-1">mdi-account-plus-outline</v-icon>
                    Invite Member
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-text-field
                class="elevation-1 px-2"
                density="compact"
                variant="solo"
                label="Find member by name or email"
                prepend-inner-icon="mdi-magnify"
                single-line
                v-model="search"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-row justify-md="end">
                <v-col cols="12" md="6">
                  <v-autocomplete
                    density="compact"
                    solo
                    prefix="Status:"
                    :items="memberItems"
                    v-model="defaultMemberItem"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col v-if="selectedMembers.length > 1" cols="12">
              <v-row justify-md="start" class="px-3">
                <v-btn text class="error capitalize" @click="removeMultiple">
                  Delete Selected Items
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :items="filteredMembers"
                :loading="loadingMembers"
                :search="search"
                show-select
                v-model="selectedMembers"
                hide-default-footer
                item-key="account.email"
              >
                <template v-slot:item.name="{ item }">
                  <v-row align="center">
                    <span class="mr-2">
                      <v-img
                        style="height: 30px; width: 30px"
                        :lazy-src="require('../../assets/avatar.png')"
                        :src="require('../../assets/avatar.png')"
                      />
                    </span>
                    <span>{{ item.name }}</span>
                  </v-row>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="pointer"
                        size="20"
                      >
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <v-list>
                      <v-list-item link exact @click="manageUser(item)">
                        <v-list-item-content class="text-12">
                          Manage
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item link exact @click="removeUser(item)">
                        <v-list-item-content class="text-12">
                          Remove
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
        <manage-users ref="manageUsers" :user="member" :title="title" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ResponseHelper from "../../utils/ResponseHelper";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ManageUsers from "./ManageUsers";
export default {
  name: "IndexPage",
  components: {
    ManageUsers,
  },
  data() {
    return {
      search: "",
      memberItems: [
        { text: "All", value: "all" },
        { text: "Admin", value: "admin" },
        { text: "Manager", value: "manager" },
        { text: "Viewer", value: "viewer" },
      ],
      defaultMemberItem: "all",
      headers: [
        { text: "Name", value: "name", sortable: true, orderable: true },
        { text: "Email", value: "account.email" },
        { text: "Status", value: "status" },
      ],
      selectedMembers: [],
      members: [],
      member: {},
      dialog: false,
      title: "",
      loadingMembers: false,
      toast: new ResponseHelper(),
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    filteredMembers() {
      if (this.defaultMemberItem === "all") {
        return this.members;
      } else {
        return this.members.filter((x) => x.role === this.defaultMemberItem);
      }
    },
  },
  created() {
    this.getMembers();
  },
  methods: {
    ...mapActions({
      get_members: "teams/get_members",
      remove_member: "teams/remove_member",
      remove_members: "teams/remove_members",
    }),
    ...mapMutations("auth", ["USER_DATA"]),
    async getMembers() {
      this.loadingMembers = true;
      try {
        const response = await this.get_members();
        this.members = response.data.teams;
        this.USER_DATA(response.data.user);
        this.loadingMembers = false;
      } catch (e) {
        this.loadingMembers = false;
        console.log(e);
      }
    },
    addUser() {
      this.title = "Manage Users";
      this.$refs.manageUsers.toggleDialog(true);
    },
    manageUser(user) {
      this.title = "Update User";
      this.member = Object.assign({}, user);
      setTimeout(() => {
        this.$refs.manageUsers.toggleDialog(true);
      }, 0);
    },
    async removeUser(user) {
      const confirmDelete = confirm("This action is not reversible");
      if (!confirmDelete) return;
      this.loadingMembers = true;
      try {
        const response = await this.remove_member(user);
        this.loadingMembers = false;
        await this.getMembers();
        this.toast.sendSuccess(response);
        console.log(response);
      } catch (e) {
        this.loadingMembers = false;
        this.toast.sendError(e);
      }
    },
    async removeMultiple() {
      const confirmDelete = confirm("This action is not reversible");
      if (!confirmDelete) return;
      const ids = [];
      this.selectedMembers.map((x) => {
        ids.push(x.id);
      });
      this.loadingMembers = true;
      try {
        const response = await this.remove_members(ids);
        this.loadingMembers = false;
        await this.getMembers();
        this.toast.sendSuccess(response);
        this.selectedMembers = [];
      } catch (e) {
        this.loadingMembers = false;
        this.toast.sendError(e);
      }
    },
  },
};
</script>

<style scoped>
.border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
