<template>
  <div>
    <v-dialog persistent max-width="400" v-model="dialog">
      <v-card class="elevation-0 pa-5 text-left">
        <h2 class="mb-5">{{ title }}</h2>
        <v-form ref="manageUser">
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <label>Email</label>
              <v-text-field
                class="my-0 y-0"
                solo
                dense
                rounded
                flat
                type="email"
                background-color="secondary"
                v-model="form.email"
                placeholder="Enter user email"
                :rules="validate.required('Link label')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="my-0 py-0 mt-n3">
              <label>Role</label>
              <v-autocomplete
                class="my-0 y-0"
                solo
                dense
                rounded
                flat
                type="text"
                background-color="secondary"
                placeholder="Select a role"
                v-model="form.role"
                :items="roles"
                :rules="validate.required('Link label')"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="justify-end px-0 mx-0">
          <v-btn
            text
            small
            class="primary capitalize rounded"
            @click="addUser"
            :loading="loading"
          >
            {{ buttonText }}
          </v-btn>
          <v-btn
            text
            small
            class="secondary black--text capitalize rounded"
            @click="toggleDialog(false)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormValidation from "../../utils/FormValidation";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  name: "ManageUsers",
  props: {
    user: {
      default: null,
      type: Object,
    },
    title: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      dialog: false,
      form: {},
      roles: [
        { text: "Admin", value: "admin" },
        { text: "Manager", value: "manager" },
        { text: "Viewer", value: "viewer" },
      ],
      buttonText: "Add User",
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      add_member: "teams/add_member",
      update_member: "teams/update_member",
    }),
    toggleDialog(value) {
      if (value === true && Object.keys(this.user).length !== 0) {
        this.form.id = this.user.id;
        this.form.role = this.user.role;
        this.form.email = this.user.account.email;
        this.buttonText = "Update User";
      } else if (value === false) {
        this.buttonText = "Add User";
        this.$parent.member = {};
        this.$refs.manageUser.reset();
      }
      this.dialog = value;
    },
    async addUser() {
      this.loading = true;
      try {
        const response =
          this.buttonText === "Add User"
            ? await this.add_member(this.form)
            : await this.update_member(this.form);
        this.loading = false;
        this.toast.sendSuccess(response);
        await this.$parent.getMembers();
        this.toggleDialog(false);
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
  },
};
</script>
